// 2023 fcavalcantirj

document.addEventListener("DOMContentLoaded", function(e) {
    
    const input = document.getElementById("search-input");
    const searchBtn = document.getElementById("search-btn");

    const expand = () => {
      //document.getElementById("search-input").expanded = "true"
      searchBtn.classList.toggle("close");
      input.classList.toggle("square");
    };

    searchBtn.addEventListener("click", expand);


     document.getElementById('content').onsubmit = function() {
        console.log(`onSubmit.....`)


        formSubmit()

        return false;
    };

});


const formSubmit = () => {

  console.log(`formSubmit`)

  let url = document.getElementById("search-input").value

  if (!url) {
    //doSomething
  }


  fetch(`https://leadsapi.weedpedia.info/?url=${url}`, {
     headers: {
        'Accept': 'application/json',
     }
   })
  .then(response => response.json())
  .then(json => {
    console.log(json)
    document.getElementById("result").innerHTML = JSON.stringify(json, null, 2)
  }).catch((error) => {
  console.log(error)
  json = "{\"host\":\"abraceesperanca.org.br\",\"emails\":[],\"twitter\":[\"https://twitter.com/abraceesperanca\"],\"facebook\":[\"https://www.facebook.com/abraceesperanca\"],\"telephone\":[\"tel:+558308000420525\"],\"linkedin\":[],\"fax\":[],\"instagram\":[\"https://www.instagram.com/abraceesperanca/\"],\"modem\":[]}"
  document.getElementById("result").innerHTML = error.message
  
});



}








// updated 2019
// const input = document.getElementById("search-input");
// const searchBtn = document.getElementById("search-btn");

// const expand = () => {
//   searchBtn.classList.toggle("close");
//   input.classList.toggle("square");
// };

// searchBtn.addEventListener("click", expand);




//  old version / jquery
//
// function expand() {
//   $(".search").toggleClass("close");
//   $(".input").toggleClass("square");
//   if ($('.search').hasClass('close')) {
//     $('input').focus();
//   } else {
//     $('input').blur();
//   }
// }
// $('button').on('click', expand);
//
